import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Image from '../components/image';
import PressQuotes from '../components/press-quotes';

import './about.scss';

const AboutPage = () => {
  const { t } = useTranslation('About');

  return (
    <Layout>
      <SEO title={t('title')} />
      <div className="st-p-landing__simple-hero">
        <div className="container">
          <h1 className="st-p-landing__hero-title">{t('title')}</h1>
        </div>
      </div>
      <section className="st-p-landing__section">
        <div className="container">
          <p className="subtitle is-4">{t('mission.paragraph')}</p>
        </div>
      </section>
      <hr />
      <div className="st-p-landing__section">
        <div className="container">
          <div className="columns is-variable is-8">
            <div className="column is-half">
              <h2 className="title is-3 is-spaced">{t('whoWeAre.title')}</h2>
              <p className="subtitle is-5">{t('whoWeAre.paragraph')}</p>
              <div className="st-p-about__vc-logos">
                <a href="https://btov.vc/" target="_blank" className="st-p-about__vc-logo is-btov">
                  <Image filename="btov.png" />
                </a>
                <a
                  href="http://en.zhenfund.com/"
                  target="_blank"
                  className="st-p-about__vc-logo is-zhen-fund"
                >
                  <Image filename="zhen-fund.png" />
                </a>
              </div>
            </div>
            <div className="column is-half">
              <h2 className="title is-3 is-spaced">{t('jobs.title')}</h2>
              <p className="subtitle is-5">
                <Trans i18nKey="About:jobs.paragraph">
                  <a href="https://angel.co/company/stamp-5/jobs"></a>
                  <a href="mailto:jobs@stampit.co"></a>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="st-p-landing__section is-grey">
        <div className="container">
          <PressQuotes />
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
